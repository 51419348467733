<template>
  <div class="personal-center-index">
    <div class="num-grid-box">
      <div style="background: #7cacff">
        <div style="display: flex; align-items: center">
          <img src="../../../assets/image/student_num.png" style="width: 50px; height: 50px; margin-right: 12px" />
          <div>学生数量</div>
        </div>
        <div>1020</div>
      </div>
      <div style="background: #ac7eff">
        <div style="display: flex; align-items: center">
          <img src="../../../assets/image/task_num.png" style="width: 50px; height: 50px; margin-right: 12px" />
          <div>任务数量</div>
        </div>
        <div>12</div>
      </div>
      <div style="background: #64dbb5">
        <div style="display: flex; align-items: center">
          <img src="../../../assets/image/no_comment.png" style="width: 50px; height: 50px; margin-right: 12px" />
          <div>待批阅任务</div>
        </div>
        <div>4</div>
      </div>
      <div style="background: #ef8a96">
        <div style="display: flex; align-items: center">
          <img src="../../../assets/image/finish_num.png" style="width: 50px; height: 50px; margin-right: 12px" />
          <div>任务完成量</div>
        </div>
        <div>6</div>
      </div>
    </div>
    <div class="table-grid-box">
      <div style="padding: 20px">
        <div id="echarts" style="width: 100%; height: 100%"></div>
      </div>
      <div style="padding: 20px">
        <div style="display: flex; justify-content: space-between; margin-bottom: 12px">
          <div>待批阅任务</div>
          <el-link type="info" :underline="false" @click="toEvaluate">查看全部</el-link>
        </div>
        <el-table :data="tableDataOne" :max-height="325" style="width: 100%" :header-cell-style="{ background: '#f6f6f6', color: '#35323B', fontWeight: 400 }">
          <el-table-column prop="name" label="任务名称"> </el-table-column>
          <el-table-column prop="add_user" label="创建人" width="100px"> </el-table-column>
          <el-table-column prop="add_time" label="创建时间"> </el-table-column>
          <el-table-column prop="start_time" label="开始时间"> </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="table-grid-box" style="grid-template-columns: 2fr 1fr; margin-bottom: 0">
      <div style="padding: 20px">
        <div style="margin-bottom: 12px">成绩分布</div>
        <el-table :data="tableDataTwo" :max-height="325" style="width: 100%" :header-cell-style="{ background: '#f6f6f6', color: '#35323B', fontWeight: 400 }">
          <el-table-column prop="name" label="任务名称"> </el-table-column>
          <el-table-column prop="top_score" label="最高分" width="150"> </el-table-column>
          <el-table-column prop="low_score" label="最低分" width="150"> </el-table-column>
          <el-table-column prop="average_score" label="平均分" width="150"> </el-table-column>
        </el-table>
      </div>
      <div style="padding: 20px">
        <div style="margin-bottom: 12px">各任务进度</div>
        <el-table :data="tableDataThree" :max-height="325" style="width: 100%" :header-cell-style="{ background: '#f6f6f6', color: '#35323B', fontWeight: 400 }">
          <el-table-column prop="name" label="任务名称"> </el-table-column>
          <el-table-column prop="progress" label="任务进度" width="200">
            <template slot-scope="scope">
              <el-progress :percentage="scope.row.progress" :color="scope.row.color"></el-progress>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import * as ECharts from "echarts";

export default {
  name: "Index",
  components: {},
  props: {},
  data() {
    return {
      tableDataOne: [
        { name: "妆容大揭秘：打造夏日清爽妆容", add_user: "王明", add_time: "2023-03-24 11:33:18", start_time: "2023-03-31 10:00:00" },
        { name: "智能家居发布会：家电科技改变生活", add_user: "刘芳", add_time: "2023-04-10 19:33:08", start_time: "2024-04-11 10:00:00" },
        { name: "时尚鞋靴探索", add_user: "李艳", add_time: "2024-04-11 10:00:00", start_time: "2023-04-15 10:00:00" },
        { name: "家电购物狂欢", add_user: "李欣荣", add_time: "2024-04-13 16:06:02", start_time: "2023-04-30 10:00:00" },
      ],
      tableDataTwo: [
        { name: "妆容大揭秘：打造夏日清爽妆容", top_score: "98分", low_score: "28分", average_score: "62分" },
        { name: "智能家居发布会：家电科技改变生活", top_score: "84分", low_score: "63分", average_score: "74分" },
        { name: "时尚鞋靴探索", top_score: "89分", low_score: "34分", average_score: "54分" },
        { name: "家电购物狂欢", top_score: "74分", low_score: "32分", average_score: "46分" },
      ],
      tableDataThree: [
        { name: "妆容大揭秘：打造夏日清爽妆容", progress: 40, color: "#7CACFF" },
        { name: "智能家居发布会：家电科技改变生活", progress: 50, color: "#AC7EFF" },
        { name: "时尚鞋靴探索", progress: 80, color: "#64DBB5" },
        { name: "家电购物狂欢", progress: 40, color: "#7CACFF" },
        { name: "美妆达人秀：化妆技巧分享", progress: 50, color: "#AC7EFF" },
      ],
      echarts: null,
      options: {
        title: {
          text: "任务完成数(Top10)",
        },
        tooltip: {
          trigger: "axis",
          formatter: (params) => {
            var relVal = params[0].name;
            for (var i = 0, l = params.length; i < l; i++) {
              relVal += "<br/>" + params[i].marker + "任务完成数：" + params[i].value + "次";
            }
            return relVal;
          },
        },
        grid: {
          right: "5%",
          bottom: "3%",
          left: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: ["雨晨", "宇轩", "思涵", "瑞雪", "明阳", "心怡", "浩然", "晨曦", "婉婷", "晨风"],
          axisLabel: {
            textStyle: {
              color: "#86909C",
            },
          },
          axisLine: {
            lineStyle: {
              color: "#86909C",
            },
          },
        },
        yAxis: {
          type: "value",
          name: "任务完成数",
          axisLabel: {
            textStyle: {
              color: "#86909C",
            },
          },
          axisLine: {
            lineStyle: {
              color: "#86909C",
            },
          },
          splitLine: {
            lineStyle: {
              type: "dashed",
            },
          },
        },
        series: {
          type: "bar",
          color: "#7CACFF",
          barWidth: 25,
          data: [10, 9, 8, 7, 6, 5, 5, 4, 4, 3],
        },
      },
    };
  },
  computed: {},
  methods: {
    init() {
      const echarts = ECharts.init(document.getElementById("echarts"));
      if (!echarts) return;
      echarts.setOption(this.options);
      this.echarts = echarts;
    },
    toEvaluate() {
      this.$router.push({ path: '/teacher/work/index' });
    }
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped lang="scss">
.personal-center-index {
  box-sizing: border-box;
  height: 100%;
  padding: 30px;
  overflow-y: auto;
  .num-grid-box {
    display: grid;
    grid-template-rows: 100px;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 16px;
    > div {
      padding: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;
      font-size: 24px;
      color: #fff;
      font-weight: bold;
    }
  }
  .table-grid-box {
    display: grid;
    grid-template-rows: 400px;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
    margin: 24px 0;
    > div {
      border: 1px solid #e7e7e7;
    }
  }
}
</style>
